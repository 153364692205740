<template>
  <div class="water-control ly-wrap">
    <drug-nav selected="2"></drug-nav>
    <div class="error-wrap" v-if="isError">
      <div class="error-img"></div>
      <p>{{ errorMsg || '系统异常' }} </p>
    </div>
    <div class="ly-center water-control-wrap" v-else>
      加药-控制
    </div>
    <div class="page-copyright">- 技术支持：云昇新控 -</div>
  </div>
</template>

<script>

import DrugNav from './modules/DrugNav.vue'
import moment from "moment";

export default {
  name: 'WaterView',
  components: {DrugNav},
  filters: {
    formatData(time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  computed: {},
  data() {
    return {
      isError: false,
      pumpSwitch: 'left'
    }
  },
  mounted() {
    this.loadBase()
  },
  created() {
  },
  methods: {
    async loadBase() {


    },
  },
  destroyed: function () { // 离开页面生命周期函数
  },
}
</script>

<style scoped lang="less">
.water-control {
  width: 100%;
  min-height: 100%;
  background-color: #ffffff;
}

@import '../../assets/less/view.less';
</style>

